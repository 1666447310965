@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  padding-right: 0px !important;
}

body {
  margin: 0;
  background-color: #121212;
  font-family: 'Montserrat', sans-serif;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
