.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  border-radius: 16px;
  cursor: pointer;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3a3a3c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #2c2c2e;
  box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.1), 0px 1px 2px rgba(255, 255, 255, 0.06);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: transparent;
}

input:focus + .slider {
  box-shadow: 0 0 1px transparent;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
