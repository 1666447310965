.deposit {
  .content {
    @apply text-white py-10 flex flex-col mx-auto;
    max-width: 880px;
    min-height: calc(100vh - 10rem);

    .earning {
      @apply flex flex-col py-10 gap-9 justify-center mt-8 rounded-2xl w-full;
      background: #2d2e35;

      &__deposit {
        @apply flex-grow flex items-end mt-8 justify-center;

        .stake-deposit {
          background: #00ed76;
          &.manage {
            background: transparent;
            color: #00ed76;
            border: 1px solid #00ed76;
          }
        }
        .fees-deposit {
          background: #d1adff;
          &.manage {
            background: transparent;
            color: #d1adff;
            border: 1px solid #d1adff;
          }
        }
        .node-deposit {
          background: #78a7ff;
          &.manage {
            background: transparent;
            color: #78a7ff;
            border: 1px solid #78a7ff;
          }
        }
      }

      &__mode {
        @apply flex flex-col pl-7 text-grey300 text-base gap-2;
        border-right: 1px solid #3b4245;
        min-height: 134px;

        &.first {
          @apply pl-0;
        }

        &.last {
          @apply pr-0 border-none;
        }

        &__title {
          @apply font-semibold text-xl text-grey25 mb-2 flex flex-col items-center;
        }

        &__item {
          @apply flex gap-3;
          img {
            @apply w-5 h-5;
          }
        }
      }
    }

    &__comment {
      @apply w-full mt-2 px-8 py-4 rounded-2xl text-sm text-white font-medium;
      background: #202024;
    }

    &__status {
      @apply w-full mt-2 px-8 py-4 rounded-2xl text-sm text-white font-medium gap-2 flex flex-col;
      background: #202024;

      &__label {
        @apply flex items-center gap-2;
      }
      &__value {
        @apply text-grey700 flex items-center gap-1;

        &.positive {
          @apply text-primary;
        }
      }

      &__box {
        @apply border border-solid border-grey600 -mx-4 px-4 py-2 rounded-lg gap-2 flex flex-col;
        background: #2d2e35;
      }
    }
  }
}
