.content {
  @apply flex flex-col w-full items-center pt-11 gap-4;
  flex: 1;

  &__title {
    @apply text-3xl font-semibold mb-3 text-white;
  }

  &__guide {
    @apply text-sm text-grey300 font-normal w-96;

    a {
      @apply text-primary500 cursor-pointer;
    }
  }
}
